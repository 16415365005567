
import Store from '../../redux/store/store';
import { TokenExpired, GetAccessToken } from '../../redux/AuthSlice/AuthSlice';
import { sentryInfo } from '../../common/Utils/Sentry/Sentry';
export const HTTPMethod = {
  Get: 'GET',
  Post: 'POST',
  Put: 'PUT',
  Delete: 'DELETE',
  Patch: 'PATCH'
};
export const ContentType = {
  Json: 1,
  FormData: 2
};
export const accessTokenObject = {
  getAccessToken: null
}
export const callApi = async (method = HTTPMethod.Get, url = "", requestParameter = null, processName = "event-api-call", contentType = 1) => {
  const isAccessTokenExpired = await checkTokenExpiration();
  if (isAccessTokenExpired) {
    Store.dispatch(GetAccessToken({ isAccessToken: true }));
    if (accessTokenObject.getAccessToken)
      await accessTokenObject.getAccessToken;
  }
  let baseUrl = process.env.REACT_APP_BASE_API;
  let callUrl = baseUrl + url;
  const accessToken = localStorage.getItem("accessToken") ? "Bearer " + localStorage.getItem("accessToken") : '';
  const preHeaders = {
    "Authorization": accessToken,
    "Allow": "application/json;",
    "Content-type": "application/json"
  };
  let requestBody = null;
  if (contentType === ContentType.FormData) {
    requestBody = requestParameter ? requestParameter : requestBody;
  } else {
    requestBody = requestParameter ? JSON.stringify(requestParameter) : requestBody;
  }
  let response;
  try {
     response = await fetch(`${callUrl}`, {
      method: method,
      headers: { ...preHeaders },
      body: requestBody,
    })
    if (response) {
      if (response.status == 401) {
        if (!window.isTokenApiCalled) {
          window.isTokenApiCalled = true;
          Store.dispatch(TokenExpired());
          sentryInfo(processName,"fail",{reqBody:requestBody,resBody:response,statusCode:response.status})
        } else {
          return;
        }
      } else if (response.status == 403) {
        sentryInfo(processName,"fail",{reqBody:requestBody,resBody:response,statusCode:response.status})
        window.location.href = "/forbidden";
        return;
      }
      else if (response.status == 404 || response.status == 400) {
        sentryInfo(processName,"fail",{reqBody:requestBody,resBody:response,statusCode:response.status})
        window.location.href = "/not-found";
        return;
      } else {
        const data = await response.json();
        if (data){
          if(processName !== "event-api-call") {
            sentryInfo(processName,"success",{reqBody:requestBody,resBody:data,statusCode:response?.status,})
          }
          return data;
        }
      }
    }
  } catch (err) {
    console.error(err);
    sentryInfo(processName,"fail",{reqBody:requestBody,resBody:response,statusCode:response?.status,exception:err ?? response})
  }
}
export const exportCallApi = async (exportCallApiMethod = HTTPMethod.Get, url="", requestParams = null, contentType = 1) => {
  const baseExportCallAPiUrl = process.env.REACT_APP_BASE_API;
  const callExportCallApiUrl = baseExportCallAPiUrl + url;
  const accessToken = localStorage.getItem("accessToken") ? "Bearer " + localStorage.getItem("accessToken") : '';
  const preCallApiHeaders = {
    "Authorization": accessToken,
    "Allow": "application/json;",
    "Content-type": "application/json"
  };
  let exportCallApiRequestBody = null;

  if (contentType === ContentType.FormData) {
    exportCallApiRequestBody = requestParams ? requestParams : exportCallApiRequestBody;
  } else {
    exportCallApiRequestBody = requestParams ? JSON.stringify(requestParams) : exportCallApiRequestBody;
  }
  try {
    const request = await fetch(`${callExportCallApiUrl}`, {
      method: exportCallApiMethod,
      headers: { ...preCallApiHeaders },
      body: exportCallApiRequestBody,
    });

    if (request && request.status == 401) {
      if (!window.isTokenApiCalled) {
        window.isTokenApiCalled = true;
        Store.dispatch(TokenExpired());
      }
      else {
        return;
      }
    } else {
      if (request)
        return request;
    }
  } catch (err) {
    console.error(err);
  }
};

export const checkTokenExpiration = async () => {
  const localStorageAuth0Key = `@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::${process.env.REACT_APP_AUTH0_AUDIENCE}::openid profile email offline_access`;
  const tokenData = JSON.parse(localStorage.getItem(localStorageAuth0Key));
  if (tokenData?.expiresAt) {
    const expireDate = new Date(tokenData.expiresAt * 1000);
    const currentDate = new Date();
    return expireDate - currentDate <= 0;
  }
  return false; 
};