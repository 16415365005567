import * as Sentry from "@sentry/react";
import packageJson from '../../../../package.json';
import sentryEventConfig from "../../../sentryEventConfig.json";
export const sentryInfo = (processName, operationName, contextData = null) => {
    if (process.env.REACT_APP_SENTRY_DSN && checkIfProcessIsEnabled(processName,operationName)) {
        logGeneralTransaction(processName, operationName, contextData);
    }
};
const checkIfProcessIsEnabled = (processName, operation) => {
    if (sentryEventConfig[processName] && sentryEventConfig[processName][operation]) {
      return sentryEventConfig[processName][operation] === 1;
    }
    return false;
  };
export const setSentryUser = (userId = 0) => {
    const userData = userId == 0 && localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : { user_id:userId };
    Sentry.setUser({ username: userData?.user_id });
}

const logGeneralTransaction = (processName, operationName, contextData) => {
    Sentry.withScope((scope) => {
        let reqBody = "";
        let resBody = "";
        try {
            if(contextData?.reqBody) {
                reqBody =  btoa(JSON.stringify(contextData?.reqBody))
            }
            if(contextData?.resBody) {
                resBody =  btoa(JSON.stringify(contextData?.resBody))
            }
        } catch (error) {
            reqBody = JSON.stringify(contextData?.reqBody)
            resBody = JSON.stringify(contextData?.resBody)
        }
        if (operationName == "fail") {
            scope.setContext("CustomData", {
                ProcessName: processName,
                ProcessOperation: operationName,
                exception: contextData?.exception ? btoa(contextData?.exception) : "",
                statusCode: contextData?.statusCode || 0,
                reqBody: reqBody,
                resBody: resBody,
                TimeStamp: new Date(),
                environment: getEnvironmentName(),
            });
        } else {
            scope.setContext("CustomData", {
                ProcessName: processName,
                ProcessOperation: operationName,
                Timestamp: new Date(),
                environment: getEnvironmentName()
            });  
        }
        scope.setTag("process_name", processName);
        scope.setTag("process_operation", operationName);
        scope.setTag('browser_timezone',Intl.DateTimeFormat().resolvedOptions()?.timeZone)
        scope.setTag("environment", getEnvironmentName());
        Sentry.startSpan({
            name: processName,
            op: operationName,
            forceTransaction:true
        }, (span) => {
            Sentry.withActiveSpan(span, () => {
                Sentry.startSpan({
                    op: "process-details",
                    name: processName,
                }, (childSpan) => {
                    if (contextData && typeof contextData == "object") {
                        Object.keys(contextData).forEach((key) => {
                            childSpan.setAttribute(key, (String(contextData[key] || ""))?.replace(/ /g, "-"));
                        });
                        childSpan.end();
                    }
                });
            });
            span.end();
        });
    });
}

const getEnvironmentName = () => {
    return process.env.REACT_APP_ENVIRONMENT || 'development';
}
const SentryInit = () => {
    const sentryDsn = process.env.REACT_APP_SENTRY_DSN; // This contains sentry DSN
    if (sentryDsn && window.location.origin !== "http://localhost:3000") {
        Sentry.init({
            dsn: sentryDsn,
            environment: getEnvironmentName(),
            release: packageJson.version,
            integrations: [
                Sentry.browserTracingIntegration(), //Capture performance data for the Browser.
                Sentry.captureConsoleIntegration({ levels: ['info'] }), //Captures all Console API calls via `captureException` or `captureMessage`.
                Sentry.httpClientIntegration(), //Captures errors on failed requests from Fetch and XHR and attaches request and response information.
                // Sentry.replayIntegration() //Capture a video-like reproduction of what was happening in the user's browser.
            ],
            sendDefaultPii: true,
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [/^https:\/\/yourserver\.io\/api/],
            // Session Replay
            // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }
};
export default SentryInit;